(function() {
    var items = [];
    var observer;

    // Get targets
    function getTargets(target) {
        var children = [];

        if (target && target.children && target.children.length > 1) {
            children = Array.prototype.slice.call(target.children);
        } else {
            children = [target];
        }

        return children;
    }

    // Reveal
    function reveal(target) {
        var targets = getTargets(target);

        anime({
            targets: targets,
            opacity: [0, 1],
            translateY: [60, 0],
            duration: 750,
            delay: anime.stagger(100, { start: 150 }),
            easing: 'cubicBezier(0.47, 0.32, 0.29, 1.01)',
            begin: function() {
                target.style.visibility = 'visible';
            },
            complete: function() {
                target.style.visibility = 'visible'; // Needed as begin sometimes doesn't fire if page/tab isn't active
            }
        });
    }

    // Get intersect
    function intersect(entries, observer) {
        for (var i = 0; i < entries.length; i++) {
            var entry = entries[i];

            if (entry.isIntersecting) {
                reveal(entry.target);
                observer.unobserve(entry.target);
            }
        }
    }

    // Setup intersection observer
    function setupIntersectionObserver() {
        observer = new IntersectionObserver((entries, observer) => {
            intersect(entries, observer);
        });

        items = Array.prototype.slice.call(document.querySelectorAll('[data-reveal]'));

        for (var i = 0; i < items.length; i++) {
            observer.observe(items[i]);
        }
    };

    // Initialise reveal
    function init() {
        setupIntersectionObserver();
    }

    // Perform init
    init();
})();